.container {
  width: 100%;
  height: 900px;
  position: relative;
  padding-top: 170px;
  padding-left: 50px;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.apearAnimation {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.innerContainer {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;

}


.left {
  width: 44%;
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  position: relative;
  z-index: 2;
}

.right {
  width: 56%;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.titleLabel {
  padding: 10px 20px;
  color: var(--green);
  font-family: 'Bold';
  background-color: rgba(87, 185, 71, 0.1);
  width: 190px;
  text-transform: uppercase;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 5px;
  font-size: 0.94rem;
}

.left h1 {
  font-family: 'Bold';
  color: var(--darkBlue);
  font-size: 2.5rem;
  margin-bottom: 10px;
  width: 575px;
}

.left p {
  font-family: 'Medium';
  color: var(--darkBlue);
  line-height: 35px;
  width: 575px;
}


.backgroundCity {
  position: absolute;
  height: 600px;
  width: 100%;
  background: url(../../../../assets/images/landing_page/header/city.png) no-repeat scroll center top;
  top: 380px;
  /*   z-index: -3; */
  z-index: 0;
  opacity: 0;
  left: 0;
}

/* .backgroundCity img {
  position: absolute;
  top: -600px;
  z-index: -3;
  width: 100%;
} */

.grass {
  margin-top: -90px;
  position: relative;
  /* z-index: -2; */
  z-index: 1;
  background: url(../../../../assets/images/landing_page/header/grass.png) no-repeat scroll center top;
  background-size: cover;
  height: 170px;
  opacity: 0;
  width: 110%;
  left: -50px;
}

.filler {
  width: 100%;
  height: 95px;
  background-color: var(--green);
  position: absolute;
  bottom: -24px;
}

.glow {
  height: 230px;
  width: 49%;
  margin-left: auto;
  margin-top: -100px;
  margin-bottom: -210px;
  background: url(../../../../assets/images/landing_page/header/glow.png) no-repeat scroll center top;
  position: relative;
  background-position-x: -280px;
  /* z-index: 2; */
  z-index: 4;
  opacity: 0;
}

.tree {
  opacity: 0;
  position: relative;
  /* z-index: 1; */
  z-index: 2;
}

.treeMobile {
  display: none;
  opacity: 0;
}

/* first cirle rotation */

@-webkit-keyframes rotating-first

/* Safari and Chrome */
  {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(-30deg);
    -o-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }
}

@keyframes rotating-first {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(-30deg);
    -moz-transform: rotate(-30deg);
    -webkit-transform: rotate(-30deg);
    -o-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }
}

/* second cirle rotation */

@-webkit-keyframes rotating-second

/* Safari and Chrome */
  {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(25deg);
    -o-transform: rotate(25deg);
    transform: rotate(25deg);
  }
}

@keyframes rotating-second {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(25deg);
    -moz-transform: rotate(25deg);
    -webkit-transform: rotate(25deg);
    -o-transform: rotate(25deg);
    transform: rotate(25deg);
  }
}

/* third cirle rotation */

@-webkit-keyframes rotating-third

/* Safari and Chrome */
  {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
  }
}

@keyframes rotating-third {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -webkit-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
  }
}

/* fourth cirle rotation */

@-webkit-keyframes rotating-fourth

/* Safari and Chrome */
  {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
}

@keyframes rotating-fourth {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
}

.circles {
  position: absolute;
  /* left: 64%; */
  right: 0;
  /* transform: translateX(344%); */
  bottom: 20px;
  /* z-index: -1; */
  z-index: 1;
  width: 356px;
  height: 356px;
}

.circle {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
}

.circles p {
  font-family: 'Regular';
  color: var(--darkBlue);
  font-size: 1rem;
}

.circles div {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.circles div div {
  width: 300px;
}

.circles div:nth-child(1) {
  opacity: 0;
}

.circles div:nth-child(2) {
  opacity: 0;
}

.circles div:nth-child(3) {
  opacity: 0;
}

.circles div:nth-child(4) {
  opacity: 0;
}

.circles div:nth-child(1) img {
  -webkit-animation: rotating-first 1s linear infinite;
  -moz-animation: rotating-first 1s linear infinite;
  -ms-animation: rotating-first 1s linear infinite;
  -o-animation: rotating-first 1s linear infinite;
  animation: rotating-first 1s linear infinite;
}

.circles div:nth-child(2) img {
  -webkit-animation: rotating-second 1s linear infinite;
  -moz-animation: rotating-second 1s linear infinite;
  -ms-animation: rotating-second 1s linear infinite;
  -o-animation: rotating-second 1s linear infinite;
  animation: rotating-second 1s linear infinite;
}

.circles div:nth-child(3) img {
  -webkit-animation: rotating-third 1.1s linear infinite;
  -moz-animation: rotating-third 1.1s linear infinite;
  -ms-animation: rotating-third 1.1s linear infinite;
  -o-animation: rotating-third 1.1s linear infinite;
  animation: rotating-third 1.1s linear infinite;
}

.circles div:nth-child(4) img {
  -webkit-animation: rotating-fourth 2s linear infinite;
  -moz-animation: rotating-fourth 2s linear infinite;
  -ms-animation: rotating-fourth 2s linear infinite;
  -o-animation: rotating-fourth 2s linear infinite;
  animation: rotating-fourth 2s linear infinite;
}

.circles :nth-child(1) div:first-of-type {
  position: absolute;
  top: 25%;
  left: -131px;
  display: flex;
  justify-content: flex-end;
}

.circles :nth-child(1) div:first-of-type p {
  text-align: right;
}

.circles :nth-child(1) div:first-of-type svg {
  margin-top: 25px;
  margin-left: 5px;
}

.circles :nth-child(1) div:last-of-type {
  position: absolute;
  top: 50%;
  left: 502px;
  display: flex;
  justify-content: flex-start;
}

.circles :nth-child(1) div:last-of-type p {
  margin-top: 10px;
  margin-left: 5px;
}


.circles :nth-child(3) div {
  position: absolute;
  top: -26px;
  transform: translateX(74%);
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}


.circles :nth-child(3) div svg {
  margin-top: 25px;
  margin-right: 5px;
}

.circles :nth-child(4) div {
  position: absolute;
  top: 21px;
  transform: translateX(-200%);
  display: flex;
  justify-content: flex-end;
}

.circles :nth-child(4) div svg {
  margin-top: 7px;
  margin-left: 5px;
}


@media only screen and (max-width: 1725px) {

  .circles :nth-child(1) div:last-of-type {
    position: absolute;
    top: 60%;
    left: -431px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
  }

  .circles :nth-child(1) div:last-of-type p {
    margin-top: -7px;
    margin-right: 5px;
  }

  .circles :nth-child(3) div {
    top: 150px;
    /* transform: translateX(74%); */
    left: -262px;
    flex-direction: row;
    justify-content: flex-end;
    text-align: right;
  }


  .circles :nth-child(3) div svg {
    transform: scaleX(-1);
    margin-left: 5px;
  }
}


/* @media only screen and (max-width: 1400px) {

  .left {
    padding-left: 50px;
  }

} */

@media only screen and (max-width: 1260px) {

  .container {
    padding-top: 20px;
    padding-left: 0;
    height: auto;
  }

  .left {
    padding: 0;
    margin-bottom: 50px;
  }

  .tree {
    display: none;
  }

  .grass {
    display: none;
  }

  .glow {
    display: none;
  }

  .treeMobile {
    display: block;
    height: 800px;
    position: relative;
    z-index: 2;
  }

  .treeMobile div:first-child {
    height: 100%;
    width: 1260px;
    background-image: url(../../../../assets/images/landing_page/header/tree_mobile.svg);
    background-position-x: -15px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .treeMobile div:last-child {
    height: 60px;
    width: 100%;
    background-color: var(--green);
    position: absolute;
    bottom: 0;
    z-index: -5;
    display: flex;
    justify-content: center;
  }

  .treeMobile div:last-child div {
    height: 70px;
    background: var(--green);
    width: 800px;
    margin: 0 auto;
  }


  .backgroundCity {
    position: absolute;
    height: 600px;
    width: 100%;
    background: url(../../../../assets/images/landing_page/header/city.png) no-repeat scroll center top;
    top: 780px;
    /* z-index: -3; */
    z-index: 0;
    opacity: 0;
  }

  .innerContainer {
    flex-direction: column;
    justify-content: center;
  }

  .left {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    text-align: center;
  }

  .right {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .circles {
    left: 50%;
    transform: translateX(-50%);
  }

  .circles div div {
    display: none !important;
  }

  .tree {
    width: 350px;
    margin-left: 80px;
  }

  .grass {
    background-size: contain;
    width: 100vw;
  }

  .filler {
    display: none;
  }
}


@media only screen and (max-width: 680px) {

  .treeMobile {
    display: block;
    height: 500px;
    position: relative;
    z-index: 2;
  }

  .backgroundCity {
    top: 400px;
  }

  .treeMobile div:first-child {
    height: 100%;
    width: 680px;
    background-image: url(../../../../assets/images/landing_page/header/tree_mobile.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transform: translateX(-3px);
  }

  .treeMobile div:last-child {
    height: 20px;
    width: 100%;
    background-color: var(--green);
    position: absolute;
    bottom: 0;
    z-index: -5;
    display: flex;
    justify-content: center;
  }

  .treeMobile div:last-child div {
    height: 40px;
    background: var(--green);
    width: 800px;
    margin: 0 auto;
  }

  .circles div:nth-child(1) img {
    height: 200px;
    width: 200px;
  }

  .circles div:nth-child(2) img {
    height: 400px;
    width: 400px;
  }

  .circles div:nth-child(3) img {
    height: 600px;
    width: 600px;
  }

  .circles div:nth-child(4) img {
    height: 800px;
    width: 800px;
  }

  .left {
    padding: 0px 20px;
  }

  .left h1 {
    font-size: 2rem;
    width: 100%;
  }

  .left br {
    display: none;
  }

  .left p {
    font-size: 0.9rem;
    width: 100%;
    line-height: 25px;
  }

  .titleLabel {
    width: max-content;
  }
}

@media only screen and (max-width: 500px) {
  .circles div:nth-child(1) img {
    height: 200px;
    width: 200px;
  }

  .circles div:nth-child(2) img {
    height: 350px;
    width: 350px;
  }

  .circles div:nth-child(3) img {
    height: 500px;
    width: 500px;
  }

  .circles div:nth-child(4) img {
    height: 650px;
    width: 650px;
  }

  .left h1 {
    font-size: 1.5rem;
  }

  .left p {
    font-size: 0.8rem;
    line-height: 20px;
  }

  .titleLabel {
    font-size: 0.75rem;
  }
}

@media only screen and (max-width: 400px) {
  .left h1 {
    font-size: 1.3rem;
  }

  .left p {
    font-size: 0.7rem;
    line-height: 20px;
  }
}