.container {
  width: 100%;
  background-color: white;
  position: relative;
  z-index: 10;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.apearAnimation {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.menuDesktop {
  padding: 0px 50px;
}

.menuMobile {
  display: none;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background: #FFFFFF;
  box-shadow: 0px 5px 20px rgba(236, 236, 236, 0.4);
}

.menuMobile .burgerIcon {
  width: 20px;
  cursor: pointer;
  user-select: none;
}

.menuMobile a img {
  width: 80px;
}

nav {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  max-width: 1400px;
  margin: 0 auto 0 auto;
  position: relative;
  z-index: 10;
}

.linkContainer {
  display: flex;
}

nav ul {
  list-style-type: none;
  display: flex;
  align-items: center;
}

nav ul li {
  height: 100%;
}

nav ul li:hover a {
  color: var(--green);
  transition: all 0.2s;
  transform: scale(1.1);
}


nav ul li a {
  text-decoration: none;
  color: var(--darkBlue);
  font-family: 'Bold';
  font-size: 0.9rem;
  padding: 0px 25px;
  height: 100%;
  display: flex;
  align-items: center;
}

/* @media only screen and (max-width: 1400px) {
  nav {
    padding: 20px;
  }
} */

@media only screen and (max-width: 1200px) {
  nav ul li a {
    padding: 0px 15px;
    font-size: 0.9rem;
  }

  nav .logo {
    display: block;
    width: 80px;
  }
}

@media only screen and (max-width: 1000px) {
  .menuDesktop {
    display: none;
  }

  .menuMobile {
    display: flex;
  }
}