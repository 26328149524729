.container {
  position: relative;
  padding: 200px 50px 100px 50px;
  margin-bottom: -100px;
}

.innerContainer {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
}

.left {
  max-width: 470px;
}

.left h1 {
  font-family: 'Bold';
  color: var(--darkBlue);
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.left p {
  font-family: 'Medium';
  color: var(--darkBlue);
  font-size: 0.9rem;
}

.right {
  position: relative;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;
  padding-left: 100px;
}


/* .contactItem:nth-child(1) {
  position: absolute;
  right: 200px;
  top: -50px;
}

.contactItem:nth-child(2) {
  position: absolute;
  right: 500px;
  top: 70px;
}

.contactItem:nth-child(3) {
  position: absolute;
  right: 250px;
  top: 200px;
} */

.contactItem {
  display: flex;
  align-items: center;
}

.contactItem .contactImage {
  margin-right: 20px;
  position: relative;
}

.contactImage .status {
  background-color: white;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  padding: 5px;
}

.contactImage .status div {
  background-color: #9BC73A;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.contactItem .contactImage img {
  width: 88px;
  height: 88px;
}

.socialIcons svg:first-child {
  margin-right: 10px;
}

.socialIcons svg:hover circle {
  fill: rgba(87, 185, 71);
  fill-opacity: 0.2;
}

.socialIcons svg:hover path {
  fill: var(--green);

}

@media only screen and (max-width: 1340px) {

  .innerContainer {
    flex-direction: column;
  }

  .right {
    padding-top: 50px;
    padding-left: 0;
    width: 900px;
    margin: 0 auto;
  }

  .left {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .container {
    padding: 100px 50px 0 50px;
    /*   margin-bottom: 0; */
  }
}

@media only screen and (max-width: 1200px) {

  .left {
    width: 100%;
    max-width: unset;
    margin-bottom: 20px;
  }

  .left p,
  .left h1 {
    text-align: center;
  }

  .right {
    flex-grow: unset;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contactItem:nth-child(1) {
    position: static;
    right: unset;
    top: unset;
    margin-bottom: 20px;
  }

  .contactItem:nth-child(2) {
    position: static;
    right: unset;
    top: unset;
    margin-bottom: 20px;
  }

  .contactItem:nth-child(3) {
    position: static;
    right: unset;
    top: unset;
  }
}

@media only screen and (max-width: 860px) {
  .container {
    padding: 50px 50px 50px 50px;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 680px) {
  .left h1 {
    font-size: 2rem;
  }

  .left p {
    max-width: 300px;
  }
}


@media only screen and (max-width: 500px) {

  .container {
    padding: 50px 20px 50px 20px;
  }

  .left h1 {
    font-size: 1.5rem;

  }

  .left p {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 400px) {
  .left h1 {
    font-size: 1.3rem;
  }

  .left p {
    font-size: 0.7rem;
  }
}