.dropdown {
  width: 100%;
  position: relative;
  user-select: none;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.dropdown .dropdownBtn {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--white);
  cursor: pointer;
  font-family: 'Bold';
  width: 100%;
}

.dropdown .dropdownBtn img {
  width: 30px;
  height: 30px;
}

.dropdown .dropdownContent {
  position: absolute;
  top: 43px;
  left: 0;
  background: #fff;
  color: var(--darkBlue);
  font-family: 'Bold';
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: 999;
}

.dropdown .dropdownContent.active {
  max-height: 100px;
  transition: max-height 0.5s ease-in;
  box-shadow: 3px 3px 3px 1px rgba(0, 0, 0, 0.06);
}

.dropdown .dropdownContent .dropdownItem {
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s;
}

.dropdown .dropdownContent .dropdownItem.selected {
  color: var(--green);
}