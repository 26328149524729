.container {
  padding-top: 200px;
  padding-left: 50px;
  padding-right: 50px;
}

.innerContainer {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.left {
  /* position: relative; */
  width: max-content;
}

.right {
  width: 490px;
}

.team {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  list-style: none;
  width: max-content;
  position: relative;
}

.team li:nth-child(1) {
  grid-area: 1 / 1 / 3 / 2;
}

.team li:nth-child(2) {
  grid-area: 1 / 2 / 2 / 3;
}

.team li:nth-child(3) {
  grid-area: 2 / 2 / 3 / 3;
}

.team li {
  cursor: pointer;
  user-select: none;
  position: relative;
}

.team li img {
  width: 100%;
  height: 100%;
}

.gridWrapper {
  position: relative;
  width: max-content;
}

.logo {
  position: absolute;
  width: 70px;
  height: 70px;
  z-index: 10;
  left: 475px;
  top: 50%;
  transform: translateY(-50%);
  user-select: none;
}

.rotatingCricle {
  animation-name: rotate-left;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  position: absolute;
  width: 154px;
  height: 154px;
  z-index: 10;
  right: -77px;
  top: -77px;
}

@keyframes rotate-left {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(-90deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.nameText {
  width: 100%;
  text-align: center;
  font-family: 'Medium';
  color: var(--white);
  font-size: 0.85rem;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -60px;
  transition: all 0.2s linear;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.team li:not(:first-child):hover .nameText {
  bottom: 0;
}

.backdropBlur {
  background-color: rgba(21, 48, 96, 0.5);
}

.quoteText {
  width: 100%;
  height: 100px;
  text-align: center;
  font-family: 'Medium';
  color: var(--white);
  font-size: 0.85rem;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 55px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 1;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #FFF;
  opacity: 0.5;
  transition: all 300ms;
}

.team li:not(:first-child):hover .overlay {
  opacity: 0;
  transition: all 300ms;
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdropBlur {
    background-color: rgba(21, 48, 96, 0.5);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
  }
}

.right h1 {
  margin-top: 10px;
  font-family: 'Regular';
  color: var(--darkBlue);
  font-size: 2.5rem;
  text-transform: uppercase;
}

.right p {
  font-family: 'Medium';
  color: var(--darkBlue);
  font-size: 0.9rem;
  line-height: 35px;
  max-width: 415px;
}

.wideText {
  max-width: 100% !important;
}

.socialIcons {
  margin: 20px 0;
}

.socialIcons svg {
  cursor: pointer;
}

.socialIcons svg:first-child {
  margin-right: 10px;
}

.socialIcons svg:hover circle {
  fill: rgba(87, 185, 71);
  fill-opacity: 0.2;
}

.socialIcons svg:hover path {
  fill: var(--green);

}

@media only screen and (max-width: 1450px) {

  .container {
    padding-top: 100px;
  }

  .innerContainer {
    flex-direction: column-reverse;
  }

  .left {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .right {
    margin: 0 auto;
    width: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }

  .right p {
    max-width: 900px;
    text-align: center;
  }

  .right h1 {
    text-align: center;
  }

  .socialIcons {
    width: max-content;
    margin: 20px auto;
  }

  .rotatingCricle {
    display: none;
  }

  .wideText {
    max-width: 900px !important;
  }
}

@media only screen and (max-width: 1410px) {
  .container {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 994px) {
  .container {
    padding-top: 10px;
  }
}

@media only screen and (max-width: 860px) {
  .team {
    width: 100%;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: calc(100vw - 100px) 1fr;
  }

  .gridWrapper {
    width: 100%;
  }

  .team li {
    width: 100% !important;
    height: 100% !important;
  }

  .team li:nth-child(1) {
    grid-area: 1 / 1 / 2 / 3;
  }

  .team li:nth-child(2) {
    grid-area: 2 / 1 / 3 / 2;
    padding-top: 100%;
  }

  .team li:nth-child(3) {
    grid-area: 2 / 2 / 3 / 3;
    padding-top: 100%;
  }

  .logo {
    display: none;
  }

  .quoteText br {
    display: none;
  }

}

@media only screen and (max-width: 680px) {
  .container {
    padding-top: 10px;
  }

  .right h1 {
    font-size: 2rem;
    width: 100%;
  }

  .right p {
    font-size: 0.9rem;
    width: 100%;
    line-height: 30px;
  }

  .quoteText {
    font-size: 0.7rem;
    padding: 20px 30px;
    height: auto;
  }

  .nameText {
    bottom: 0;
    font-size: 0.8rem;
    height: 25%;
  }
}

@media only screen and (max-width: 500px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .right h1 {
    font-size: 1.5rem;

  }

  .right p {
    font-size: 0.8rem;
    line-height: 25px;
  }

  .quoteText {
    font-size: 0.7rem;
    padding: 20px 10px;
    height: auto;
  }

  .nameText {
    font-size: 0.6rem;
  }

  .socialIcons {
    margin: 10px auto;
  }
}

@media only screen and (max-width: 400px) {
  .quoteText {
    font-size: 0.6rem;
    padding: 10px 15px;
  }

  .right h1 {
    font-size: 1.3rem;
  }

  .right p {
    font-size: 0.7rem;
    line-height: 20px;
  }
}