.container {
  background-color: var(--flashWhite);
  position: relative;
  padding: 65px 50px;
}

.innerContainer {
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 100%;
}

.sideImg {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background-image: url(../../../images/landing_page/main/competencies_side.png);
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 5px;
}

.sideImg img {
  width: 100%;
}

.left {
  width: 413px;
}

.left h1 {
  font-family: 'Bold';
  color: var(--white);
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.left p {
  font-family: 'Medium';
  color: var(--white);
  font-size: 0.9rem;
  line-height: 35px;
}

.right {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  position: relative;
  justify-content: flex-end;
}

.card {
  width: 430px;
  /* height: 190px; */
  background-color: #FFF;
  display: flex;
  padding: 25px 20px 20px 20px;
  border: 1px solid #E9E9E9;
  box-shadow: 0px 10px 20px rgba(125, 125, 125, 0.1);
  border-radius: 5px;
}

.card img {
  width: 30px;
  display: block;
  margin-right: 20px;
  align-self: center;
}

.card div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.card h4 {
  font-family: 'Bold';
  font-size: 0.95rem;
  color: var(--darkBlue);
  margin-bottom: 10px;
}

.card p {
  font-family: 'Medium';
  font-size: 0.9rem;
  color: var(--darkBlue);
  line-height: 30px;
}

@media only screen and (max-width: 1410px) {
  .container {
    background: none;
  }

  .innerContainer {
    flex-direction: column;
  }

  .sideImg {
    width: 100% !important;
    background-size: cover;
    height: 450px;
    background-position: center;
    border-radius: 0;
  }

  .left {
    text-align: center;
    width: unset;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }

  .right {
    width: 100%;
    justify-content: center;
  }

}

@media only screen and (max-width: 979px) {
  .card {
    width: 500px;
  }
}

@media only screen and (max-width: 680px) {
  .container {
    padding: 50px 50px 50px 50px;
  }

  .left h1 {
    font-size: 2rem;
    width: 100%;
  }

  .sideImg {
    height: 450px;
  }

  .card {
    padding: 30px 20px 20px 20px;
    width: 450px;
  }

  /*   .left br {
    display: none;
  } */

  .left p {
    font-size: 0.9rem;
    width: 100%;
    line-height: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .card {
    width: 100%;
  }
}

@media only screen and (max-width: 570px) {
  .card {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    height: auto !important;
  }

  .card img {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .card p {
    font-size: 0.8rem;
    line-height: 25px;
  }
}

@media only screen and (max-width: 500px) {
  .container {
    padding: 30px 20px 30px 20px;
  }

  .left h1 {
    font-size: 1.5rem;

  }

  .left p {
    font-size: 0.8rem;
    line-height: 20px;
  }

  .sideImg {
    height: 350px;
  }
}

@media only screen and (max-width: 400px) {
  .left h1 {
    font-size: 1.3rem;
  }

  .left p {
    font-size: 0.7rem;
    line-height: 20px;
  }

  .card p {
    font-size: 0.7rem;
    line-height: 20px;
  }
}

@media only screen and (max-width: 340px) {
  .card p {
    font-size: 0.6rem;
  }
}

@media only screen and (max-width: 300px) {
  .card p {
    font-size: 0.5rem;
  }
}