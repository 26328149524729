.container {
  /* background-color: var(--green); */
  /* padding-top: 43.49%; */
  padding-top: 50%;
  background-image: url(../../../images/landing_page/footer/footer_tree_desktop.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.innerContainer {
  background-color: var(--green);
  padding-left: 50px;
  padding-right: 50px;
  /*  margin-top: 175px; */
}

.treeMobile {
  background: url(../../../images/landing_page/footer/footer_tree_mobile.svg);
  position: absolute;
  width: 100%;
  height: 300px;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
  display: none;
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Regular';
  color: var(--white);
  font-size: 0.9rem;
  max-width: 665px;
  margin: 0 auto 25px auto;
}

.info img {
  margin-right: 20px;
}

.bottomSection {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}

.bottomSection .sectionItem {
  width: 500px;
}

.sectionItem h4 {
  font-family: 'Bold';
  color: var(--white);
  font-size: 0.9rem;
  margin-bottom: 20px;
  text-align: center;
}

.sectionItem:first-child li {
  width: 146px;
  text-align: center;
}

.sectionItem ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
}

.sectionItem ul li a {
  font-family: 'Regular';
  color: var(--white);
  font-size: 0.9rem;
  text-decoration: none !important;

}


.bottomText {
  text-align: center;
  color: var(--darkBlue);
  font-size: 0.8rem;
  font-family: 'Medium';
  padding: 10px 0;
}

@media only screen and (max-width: 860px) {
  .container {
    padding-top: 269px;
    background-image: unset;
    background-size: unset;
    background-repeat: unset;
    background-position: unset;
  }

  .treeMobile {
    display: block;
  }
}

@media only screen and (max-width: 780px) {
  .info {
    flex-direction: column;
  }

  .info img {
    margin-bottom: 10px;
    margin-right: 0;
  }

  .info p {
    text-align: center;
  }
}


@media only screen and (max-width: 670px) {}

@media only screen and (max-width: 580px) {

  .info p,
  .sectionItem ul li,
  .sectionItem h4 {
    font-size: 0.7rem;
  }
}

@media only screen and (max-width:460px) {
  .sectionItem ul {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
  }

  .bottomSection {
    padding-bottom: 10px;
  }

  .sectionItem ul li {
    margin-bottom: 20px;
  }
}