.container {
  width: 100%;
  background-color: var(--green);
  margin-top: -5px;
  padding-bottom: 50px;
  position: relative;
  z-index: 3;
  opacity: 0;
}

.innerContainer {
  position: relative;
  max-width: 1450px;
  margin: 0 auto;
  padding-top: 205px;
  padding-left: 380px;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.apearAnimation {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.treeLine {
  position: absolute;
  right: 175px;
  top: 0;
}

.treeLineMobile {
  display: none;
}

.leaf {
  display: none;
  width: 100px;
}

.bee {
  display: none;
  width: 100px;
}

.heart {
  display: none;
  width: 100px;
}

.treeLineText {
  position: absolute;
  border: 2px solid #A1FD7A;
  border-radius: 10px;
  color: var(--white);
  padding: 10px 20px;
  font-family: "Bold";
  text-transform: uppercase;
  background-color: var(--green);
  font-size: 0.75rem;
  left: 50%;
  transform: translate(-20%);
  top: 122px;
  user-select: none;
}

.textBlock {
  max-width: 900px;
  margin-bottom: 20px;
}

.textBlock:nth-child(4) {
  margin-bottom: 40px;
}

.textBlock h2 {
  color: var(--white);
  font-family: "Bold";
  font-size: 1.88rem;
  line-height: 130%;
}

.textBlock p {
  font-family: 'Regular';
  color: var(--white);
  font-size: 0.95rem;
  line-height: 35px;
}

.shadowBox {
  max-width: 633px;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  background-color: #46AE4E;
  padding: 20px;
  margin-bottom: 25px;
}

.shadowBox div {
  display: flex;
  align-items: center;
  color: var(--white);
  font-family: 'Regular';
  font-size: 0.95rem;
}

.shadowBox div img {
  margin-right: 10px;
  width: 87px;
}


.shadowBox div:nth-child(2) {
  width: 2px;
  height: 30px;
  background-color: var(--yellow);
  margin: -3px 0;
  margin-left: 42px;
}

@media only screen and (max-width: 1430px) {
  .innerContainer {
    padding-left: 350px;
  }
}

@media only screen and (max-width: 1400px) {
  .innerContainer {
    padding-left: 320px;
  }
}

@media only screen and (max-width: 1360px) {
  .innerContainer {
    padding-left: 280px;
  }
}

@media only screen and (max-width: 1330px) {
  .innerContainer {
    padding-left: 250px;
  }
}

@media only screen and (max-width: 1300px) {
  .innerContainer {
    padding-left: 220px;
  }
}

@media only screen and (max-width: 1280px) {
  .innerContainer {
    padding-left: 200px;
  }
}

@media only screen and (max-width: 1260px) {

  .container {
    position: relative;
    z-index: 5;
  }

  .innerContainer {
    padding-left: 0;
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
  }

  .treeLine {
    position: relative;
    right: auto;
    top: auto;
    transform: translateY(-30%);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -30px;
  }

  .treeLineText {
    left: 50%;
    top: 35%;
    transform: translateX(-50%);
    width: max-content;
  }

  .treeLineDesktop {
    display: none;
  }

  .treeLineMobile {
    display: block;
    width: 2px;
    height: 200px;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(132, 251, 141, 1) 20%);
  }

  .leaf {
    display: block;
    margin-bottom: -40px;
  }

  .bee {
    display: block;
    margin-bottom: 10px;
  }

  .heart {
    display: block;
    margin-bottom: 10px;
  }

  .textBlock {
    text-align: center;
  }
}

@media only screen and (max-width: 680px) {
  .treeLine {
    transform: translateY(-50px);
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 500px) {
  .innerContainer {
    padding: 0px 20px;
  }

  .textBlock h2 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .textBlock p {
    font-size: 0.8rem;
    line-height: 30px;
  }

  .shadowBox {
    padding: 20px 10px 20px 20px;
  }

  .shadowBox div {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 400px) {
  .textBlock h2 {
    font-size: 1rem;
    margin-bottom: 5px;
  }

  .textBlock p {
    font-size: 0.7rem;
    line-height: 25px;
  }

  .shadowBox div {
    font-size: 0.7rem;
  }

  .shadowBox div img {
    margin-right: 10px;
    width: 74px;
  }

  .shadowBox div:nth-child(2) {
    width: 2px;
    height: 30px;
    background-color: var(--yellow);
    margin: -3px 0;
    margin-left: 36px;
  }

}