.container {
  padding: 140px 0 125px 50px;
}

.innerContainer {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
}

.dotsTop {
  position: absolute;
  top: -37px;
  right: -60px;
}

.dotsMid {
  position: absolute;
  transform: rotate(90deg);
  left: 50px;
  top: 45%;
}

.left {
  width: 400px;
}

.left h1 {
  font-family: 'Bold';
  color: var(--darkBlue);
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.left p {
  font-family: 'Medium';
  color: var(--darkBlue);
  font-size: 0.9rem;
  line-height: 35px;
}

.right {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding-left: 80px;
  position: relative;
}

.goalCard {
  max-width: 390px;
  background-color: var(--flashWhite);
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  /* height: 323px; */
  position: relative;
  z-index: 1;
  cursor: default;
}

.goalCard:hover {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), linear-gradient(219.88deg, #A5CD39 -3.83%, #98CA3B 19.04%, #75C141 43.63%, #50B748 69.07%, #49B147 92.41%, #379E43 120.8%, #19813D 157.06%, #006838 186.74%);
}

.goalCard:hover p,
.goalCard:hover h4 {
  color: var(--white);
}

.goalCard:hover img {
  filter: brightness(0) invert(1);
}

.goalCard img {
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}

.goalCard h4 {
  font-family: 'Bold';
  font-size: 0.95rem;
  color: var(--darkBlue);
  margin-bottom: 10px;
}

.goalCard p {
  font-family: 'Medium';
  font-size: 0.75rem;
  color: var(--darkBlue);
  line-height: 30px;
}

@media only screen and (max-width: 1350px) {
  .container {
    padding: 80px 50px 80px 50px;
  }

  .innerContainer {
    flex-direction: column;
  }

  .left {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 50px;
  }

  .left h1 {
    font-family: 'Bold';
    color: var(--darkBlue);
    font-size: 2.5rem;
    margin-bottom: 10px;
    max-width: 500px;
  }

  .left p {
    font-family: 'Medium';
    color: var(--darkBlue);
    font-size: 0.9rem;
    line-height: 35px;
    max-width: 800px;
  }

  .right {
    flex: unset;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0;
    justify-content: center;
  }

  .dotsTop {
    display: none;
  }

  .dotsMid {
    display: none;
  }
}


@media only screen and (max-width: 680px) {
  .container {
    padding: 50px 50px 50px 50px;
  }

  .left h1 {
    font-size: 2rem;
    width: 100%;
  }

  .left br {
    display: none;
  }

  .left p {
    font-size: 0.9rem;
    width: 100%;
    line-height: 30px;
  }
}

@media only screen and (max-width: 500px) {
  .container {
    padding: 30px 20px 30px 20px;
  }

  .left h1 {
    font-size: 1.5rem;
    max-width: 300px;
  }

  .left p {
    font-size: 0.8rem;
    line-height: 20px;
  }

  .goalCard {
    height: auto !important;
  }

  .goalCard p {
    font-size: 0.75rem;
    line-height: 25px;
  }
}

@media only screen and (max-width: 400px) {
  .left h1 {
    font-size: 1.3rem;
  }

  .left p {
    font-size: 0.7rem;
    line-height: 20px;
  }

  .goalCard p {
    font-size: 0.7rem;
    line-height: 20px;
  }
}

@media only screen and (max-width: 340px) {
  .goalCard p {
    font-size: 0.6rem;
  }
}

@media only screen and (max-width: 300px) {
  .goalCard p {
    font-size: 0.5rem;
  }
}