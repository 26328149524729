.container {
  /*  width: 100%;
  background-color: var(--green); */
}

.container ul {
  display: flex;
  flex-direction: column;
}

.container ul li {
  margin-bottom: 35px;
}

.container ul li a {
  color: var(--white) !important;
}

.menuHeader {
  width: 100%;
  height: 80px;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  /*   z-index: 999; */
}

.menuHeader img {
  width: 80px;
}

/* @media only screen and (max-height: 780px) {
  .container ul li {
    margin-bottom: 25px;
  }
}

@media only screen and (max-height: 650px) {
  .container ul {
    margin-top: -60px;
  }
} */