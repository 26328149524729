@font-face {
  font-family: "Light";
  src: local("Montserrat-Light"),
    url("../fonts/Montserrat-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Regular";
  src: local("Montserrat-Regular"),
    url("../fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Medium";
  src: local("Montserrat-Medium"),
    url("../fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Bold";
  src: local("Montserrat-Bold"),
    url("../fonts/Montserrat-Bold.ttf") format("truetype");
}

:root {
  --darkBlue: #153060;
  --white: #FFFFFF;
  --green: #4DB255;
  --yellow: rgba(230, 255, 77, 1);
  --flashWhite: #EDF7EE;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {
  font-size: 1rem;
}

.title-label {
  padding: 10px 20px;
  color: var(--green);
  font-family: 'Bold';
  background-color: rgba(87, 185, 71, 0.1);
  width: max-content;
  text-transform: uppercase;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 5px;
  font-size: 0.94rem;
}

.messageout-enter {
  position: absolute;
  top: 0;
  left: calc(13% + 5px);
  right: calc(13% + 5px);
  opacity: 0.01;
  transform: translateY(0%) scale(0.01);
}

.messageout-appear {
  opacity: 0;
}

/* ending ENTER animation */
.messageout-appear-active {
  opacity: 1;
  transition: all 300ms;
}

/* starting EXIT animation */
.messageout-exit {
  opacity: 1;
}

/* ending EXIT animation */
.messageout-exit-active {
  opacity: 0;
  /* transform: scale(4); */
  transition: all 300ms ease-in-out;
}

.bm-cross-button img {
  background: none !important;
}

.bm-item-list {
  overflow-y: scroll;
}

.scroll-to-top {
  background-color: var(--darkBlue);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding: 10px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  cursor: pointer;
}

.scroll-to-top img {
  width: 30px;
  -drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@media only screen and (max-width: 680px) {
  .title-label {
    width: max-content;
  }
}

@media only screen and (max-width: 500px) {
  .title-label {
    font-size: 0.75rem;
  }
}